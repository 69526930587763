import React from 'react';
import ReactDOM from 'react-dom';
import PouchDB from 'pouchdb';
import localforage from 'localforage';
import baseClient from 'src/client.webstore/api/base';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import qs from 'querystring';
import productsClient from 'src/client.webstore/api/product';
import analytics from 'src/client.webstore/utils/analytics';
import _ from 'lodash';

const cartItems = new PouchDB('cartItems');
const cartConfig = localforage.createInstance({
    name: 'cartConfig'
});

const Analytics = analytics();

let target = document.querySelector('div#cart-toaster');
if (!target) {
    target = document.createElement('div');
    target.id = 'cart-toaster';
    document.body.appendChild(target);

    ReactDOM.render(
        <ToastContainer/>,
        target
    );
}

/**
 * The namespace representing the shopping cart. All static members of this namespace
 * allow for the manipulation of a users shopping cart and aid in the checkout.
 *
 * @memberOf utils
 * @namespace
 * @example
 * cart.setCoupon('summersale2020').then(() => alert('Your coupon has been updated!'));
 *
 * cart.setBilling({
 *     name: 'Kobe Bryant'
 * }).then(() => alert('Your billing information has been updated!'));
 *
 */
const cart = {
    /**
     * Gets a properly formatted payload for `CartCalculationService`
     *
     * @async
     * @return {Promise<{paymentToken: (Object|string), cartCoupon: string, shipping: Object, shippingMethod: (Object|null), cartItems: Array, billing: Object}>}
     */
    getPayload: async () => ({
        billing: await cart.getBilling(),
        shipping: await cart.getShipping(),
        shippingMethod: await cart.getShippingMethod(),
        cartItems: await cart.get(),
        paymentToken: await cart.getPaymentToken(),
        cartCoupon: await cart.getCoupon()
    }),

    /**
     * @return {Promise<Array>} Returns a promise that resolves to an array of objects containing cart item details.
     */
    get: () => cartItems.allDocs({include_docs: true}).then(docs => docs.rows.map(d => d.doc)),

    /**
     * Calculate the totals for the cart based on submitted information.
     * @return {Promise<Object>}
     */
    calculate: async () => {
        return baseClient()
            .post(
                '/webstore/webstore_cart/calculate',
                null,
                await cart.getPayload()
            )
            .then(res => res.data)
            .catch(e => cart.clear().then(() => toast.error('One or more of the products in your shopping cart are no longer available and have been removed.')));
    },

    /**
     * Calculate the estimated shipping rate
     * @returns {Promise<Object>}
     */
    estimateShipping: async () => {
        let cost = await cartConfig.getItem('estimatedShippingCost') || {};
        const estimatedShippingAddress = await cart.getEstimatedShipping();
        const hash = await hashEstimatedShipping(_.omit(estimatedShippingAddress, 'h'));

        // Use the estimate we calculated already, if available
        if (typeof cost === 'object' && _.has(cost, hash) && _.isNumber(cost[hash])) {
            return cost[hash];
        } else if (_.isEmpty(_.trim(_.get(estimatedShippingAddress, 'zip', '')))) {
            return null;
        }

        // Retrieve a new estimate
        let payload = await cart.getPayload();
        payload.estimatedShipping = estimatedShippingAddress;

        return baseClient()
            .post(
                '/webstore/webstore_cart/estimate_shipping',
                null,
                payload
            )
            .then(res => {
                // Save a map of the costs we've already calculated so we don't bother fetching them again
                cost[hash] = Number(res.data);
                cartConfig.setItem('estimatedShippingCost', cost);
                return res.data;
            })
            .catch(e => toast.error('An estimated shipping rate could not be determined.'));
    },

    /**
     * Empty the cart of all items and clear all user data.
     * @return {Promise<*>}
     */
    clear: () => Promise.all([
        cartConfig.clear(),
        cart.get().then(cartItems => cartItems.map(item => product(item.catalogId, item.foreignKeyId).remove()))
    ]),

    /**
     * @param couponCode
     * @return {Promise<*>}
     */
    setCoupon: couponCode => cartConfig.setItem('cartCoupon', couponCode || null),

    /**
     * @return {Promise<string|null>}
     */
    getCoupon: () => cartConfig.getItem('cartCoupon'),

    /**
     * @param zip
     * @return {Promise<*>}
     */
    setZip: zip => cartConfig.setItem('cartZip', zip || null),

    /**
     * @return {Promise<string|null>}
     */
    getZip: () => cartConfig.getItem('cartZip'),

    /**
     * @param {Object} user
     * @return {Promise<*>}
     */
    setBilling: user => cartConfig.setItem('billing', user),

    /**
     * @return {Promise<Object|null>}
     */
    getBilling: () => cartConfig.getItem('billing'),

    /**
     * @param {Object} shippingAddress
     * @return {Promise<*>}
     */
    setShipping: shippingAddress => cartConfig.setItem('shipping', shippingAddress),

    /**
     * @return {Promise<Object|null>}
     */
    getShipping: () => cartConfig.getItem('shipping'),

    /**
     * @param {Object} shippingAddress
     * @return {Promise<*>}
     */
    setEstimatedShipping: async shippingAddress => {
        // Keep a hash to track changes to the estimated shipping address or cart items
        shippingAddress['h'] = await hashEstimatedShipping(_.omit(shippingAddress, 'h'));
        cartConfig.setItem('estimatedShipping', shippingAddress);
    },

    /**
     * @return {Promise<Object|null>}
     */
    getEstimatedShipping: () => cartConfig.getItem('estimatedShipping'),

    /**
     * @param {Object|null} shippingMethod An object containing the shipment and rate to use, or null for none
     *  - reference_id - The reference ID to the rate
     *  - shipment_package_id - The reference ID to the shipment package
     * @return {Promise<*>}
     */
    setShippingMethod: shippingMethod => cartConfig.setItem('shippingMethod', shippingMethod),

    /**
     * @return {Promise<Object|null>}
     */
    getShippingMethod: () => cartConfig.getItem('shippingMethod'),

    /**
     * @param {Object|string} token
     * @return {Promise<*>}
     */
    setPaymentToken: token => cartConfig.setItem('paymentToken', token),

    /**
     * @return {Promise<null|Object|string>}
     */
    getPaymentToken: () => cartConfig.getItem('paymentToken'),

    /**
     * Retrieves all applicable shipping rates based on cart data
     * @return {Promise<Array>}
     */
    shippingRates: async () => {
        return baseClient()
            .post(
                '/webstore/webstore_cart/rates',
                null,
                await cart.getPayload()
            )
            .then(res => res.data)
            .catch(e => {
                throw e;
            });
    },

    /**
     * @param params
     */
    addOem: params => {
        let pleaseWait = toast.info('Please wait, checking availability. This may take some time.', {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false
        });

        params = qs.parse(params);

        baseClient()
            .post('/webstore/webstore_oem_parts/verify', {}, params)
            .then(
                res => {
                    let {catalogId, foreignKeyId, webstoreProduct} = res.data;
                    const item = product(catalogId, foreignKeyId);
                    return item.add(Number(params.qty_1))
                        .then(() => item.settings(_.pick(_.get(webstoreProduct, 'WebstoreProduct'), ['pickup_in_store'])));
                },
                err => {
                    toast.dismiss();
                    toast.error(`${params.addItem_1} is not currently available.`);
                    throw err;
                }
            )
            .then(res => toast.dismiss(pleaseWait))
            .catch(err => console.error(err));
    }
};

let WebstoreComponents;

/**
 *
 * @param catalogId
 * @param foreignKeyId
 * @returns {{add: ((function(*=): (*))|*), update: (function(*=): *|Promise<PouchDB.Core.Response>), remove: (function(): Promise<PouchDB.Core.Response | boolean>)}}
 */
const product = (catalogId, foreignKeyId) => {
    const documentId = `${catalogId}-${foreignKeyId}`;
    let options = {
        pickup_in_store: false
    };

    return {
        /**
         *
         * @param quantity
         * @returns {Promise<PouchDB.Core.Response>|*}
         */
        add: (quantity) => {
            productsClient().getVariation(catalogId, foreignKeyId)
                .then(res => {
                    let product = res.data;
                    Analytics.action({
                        ecomm_part_detail_id: foreignKeyId,
                        ecomm_part_detail_name: product.ProductGroup.name,
                        ecomm_part_detail_quantity: quantity,
                        ecomm_part_detail_variant: `${product.size} ${product.color} ${product.option}`,
                        ecomm_part_detail_model: product.manufacturer_part_number,
                        ecomm_part_detail_group_id: product.ProductGroup.id,
                        ecomm_cart_event: quantity > 0 ? 'add_to_cart' : 'remove_from_cart',
                        ecomm_cart_quanity_added_removed: quantity,
                        tealium_event: 'ecommerce_part_modify_cart'
                    });
                });


            if (quantity === 0) {
                return cart.remove();
            }

            toast.success('Your cart has been updated.');

            if (!WebstoreComponents) {
                WebstoreComponents = new window.PSS.WebstoreComponents();
            }

            WebstoreComponents.mountCartSideSheet();

            return cartItems
                .get(documentId)
                .then(doc => {
                    doc.quantity = quantity;
                    return cartItems.put(doc);
                })
                .catch(err => {
                    if (err.status !== 404) {
                        throw err;
                    }

                    return cartItems.put({
                        _id: documentId,
                        catalogId: Number(catalogId),
                        foreignKeyId: Number(foreignKeyId),
                        quantity: Number(quantity)
                    });
                });
        },
        /**
         *
         * @returns {Promise<PouchDB.Core.Response | boolean>}
         */
        remove: () => {
            productsClient().getVariation(catalogId, foreignKeyId)
                .then(res => {
                    let product = res.data;
                    Analytics.action({
                        ecomm_part_detail_id: foreignKeyId,
                        ecomm_part_detail_name: product.ProductGroup.name,
                        ecomm_part_detail_variant: `${product.size} ${product.color} ${product.option}`,
                        ecomm_part_detail_model: product.manufacturer_part_number,
                        ecomm_part_detail_group_id: product.ProductGroup.id,
                        ecomm_cart_event: 'remove_from_cart',
                        tealium_event: 'ecommerce_part_modify_cart'
                    });
                });


            return cartItems
                .get(documentId)
                .then(doc => cartItems.remove(doc))
                .catch(err => {
                    if (err.status !== 404) {
                        throw err;
                    }

                    return Promise.resolve(true);
                });
        },
        /**
         *
         * @param quantity
         * @returns {Promise<PouchDB.Core.Response>|*}
         */
        update: (quantity) => this.add(quantity),
        /**
         * Defines settings for the product in the cart
         *
         * @param {Object} settings
         * @returns {Promise<PouchDB.Core.Response>|*}
         */
        settings: (settings) => {
            options = _.merge(options, settings || {});
            return cartItems
                .get(documentId)
                .then(doc => {
                    doc.settings = options;
                    cartItems.put(doc);
                })
                .catch(err => {
                    if (err.status !== 404) {
                        throw err;
                    }

                    return Promise.resolve(true);
                });
        }
    };
};

/**
 * Retrieves a hex string hash digest of the given shipping address and current cart items
 *
 * @param shippingAddress {Object}
 * @returns {Promise<string>}
 */
const hashEstimatedShipping = async shippingAddress => {
    let hash = '';

    // Hash the estimated shipping address info and current cart items
    if (typeof TextEncoder !== 'undefined'
        && typeof window.crypto !== 'undefined'
        && typeof window.crypto.subtle !== 'undefined'
        && typeof window.crypto.subtle.digest === 'function'
    ) {
        // Use WebCrypto API to create a hash of the estimated shipping address and the items so we can check whether it changes
        const data = new TextEncoder('utf-8').encode(JSON.stringify(_.merge({}, await cart.get(), shippingAddress)));
        // Convert the digest byte array hash to a hex string for easier reference
        const buffer = await window.crypto.subtle.digest('sha-1', data);
        hash = Array.from(new Uint8Array(buffer)).map(c => c.toString(16).padStart(2, '0')).join('');
    }

    return hash;
};

export {cart, product};