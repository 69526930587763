import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'src/client.webstore/Views/Elements/Icon';
import NameValue from './NameValue';
import {cart} from 'src/client.webstore/utils/cart';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';

class PromoCodeInput extends React.Component {
    state = {
        isEditing: false,
        couponCode: ''
    };

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        cart.getCoupon().then(coupon => this.setState({couponCode: coupon || ''}));
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isEditing && prevState.isEditing !== this.state.isEditing) {
            this.inputRef.current.focus();
        }
    }

    onSubmit = e => {
        e.preventDefault();
        this.setState({isEditing: false}, () => {
            cart.setCoupon(this.state.couponCode).then(this.props.calculate);
        });
    };

    render() {
        if (this.state.isEditing) {
            return (
                <ThemeContext.Consumer>
                    {({cs}) => (
                        <form onSubmit={this.onSubmit}>
                            <div className={cs('row')}>
                                <div className={cs('col')}>
                                    <label htmlFor={'coupon-code'} className={cs('sr-only')}>Coupon Code</label>
                                    <input
                                        type={'text'}
                                        id={'coupon-code'}
                                        className={cs('form-control')}
                                        placeholder={'Promo Code'}
                                        onChange={e => this.setState({couponCode: e.currentTarget.value})}
                                        value={this.state.couponCode}
                                        onBlur={this.onSubmit}
                                        ref={this.inputRef}
                                    />
                                </div>
                            </div>
                        </form>
                    )}
                </ThemeContext.Consumer>
            );
        }

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <button
                        type={'button'}
                        className={cs('btn', 'btn-link', 'text-secondary')}
                        onClick={() => this.setState({isEditing: true})}
                    >
                        {this.state.couponCode}
                        {' '}
                        <Icon icon={'edit'} padding={3}/>
                    </button>
                )}
            </ThemeContext.Consumer>
        );
    }
}

export default class OrderTotalsSummary extends React.Component {
    static propTypes = {
        subtotal: PropTypes.number,
        discount: PropTypes.number,
        tax: PropTypes.number,
        shipping: PropTypes.number,
        estimatedShipping: PropTypes.number,
        total: PropTypes.number,
        calculate: PropTypes.func.isRequired,
        currency: PropTypes.func.isRequired
    };

    render() {
        let {subtotal, discount, tax, shipping, estimatedShipping, total, currency} = this.props;

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <React.Fragment>
                        <div
                            className={cs('row', 'mb-3', 'border-bottom')}
                            id={'pss-order-totals'}
                        >
                            <div className={cs('col')}>
                                <h3 className={cs('text-uppercase')}>Order Totals</h3>
                                <NameValue name={'Subtotal'} value={currency(subtotal).format(true)}/>

                                {estimatedShipping !== null && shipping === null && <NameValue name={'Est. Shipping'} value={currency(estimatedShipping).format(true)}/>}

                                {shipping !== null && <NameValue name={'Shipping'} value={currency(shipping).format(true)}/>}

                                {discount !== null && <NameValue name={'Discount'} value={currency(discount).format(true)} valueClass={cs('text-primary')}/>}

                                {tax !== null && <NameValue name={'Tax'} value={currency(tax).format(true)}/>}

                                <NameValue name={'Promo Code'} value={<PromoCodeInput {...this.props}/>}/>
                            </div>
                        </div>

                        {total !== null &&
                        <div className={cs('row')}>
                            <div className={cs('col')}>
                                <NameValue name={'Total'} value={currency(total).format(true)} nameClass={'font-weight-bold'} valueClass={'font-weight-bold'}/>
                            </div>
                        </div>
                        }
                    </React.Fragment>
                )}
            </ThemeContext.Consumer>
        );
    }
}
