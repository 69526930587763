import React from 'react';
import PropTypes from 'prop-types';
import productsClient from 'src/client.webstore/api/product';
import Spinner from 'src/client.webstore/Views/Elements/Spinner';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import {CartConsumer} from 'src/client.webstore/Views/Cart/CartContext';
import _ from 'lodash';

const NameValue = (props) => (
    <ThemeContext.Consumer>
        {({cs}) => (
            <dl className={cs('row')}>
                <dt className={cs('col', 'text-right')}>
                    {props.name}
                </dt>
                <dd className={cs('col')}>
                    {props.value}
                </dd>
            </dl>
        )}
    </ThemeContext.Consumer>
);

export default class PricingAvailability extends React.Component {
    static propTypes = {
        foreignKeyId: PropTypes.number.isRequired,
        manufacturerPartNumber: PropTypes.string.isRequired,
        webstoreProduct: PropTypes.object.isRequired,
        attributes: PropTypes.array
    };

    static defaultProps = {
        attributes: []
    };

    state = {
        loading: true,
        error: false,
        salePrice: null,
        msrp: null,
        isShelf: false,
        leadTime: 5,
        quantity: 0,
        pickup: false
    };

    componentDidMount() {
        this.calculate();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.foreignKeyId !== this.props.foreignKeyId) {
            this.calculate();
        }
    }

    componentWillUnmount() {
        // Stop state updates after the component is unmounted to avoid potential memory leaks from any pending async requests
        this.setState = () => null;
    }

    calculate = () => {
        const catalogId = Number(_.get(this.props, 'webstoreProduct.catalog_id', 0));

        this.setState({loading: true}, () => {
            Promise
                .all([
                    productsClient().calculatePrice(catalogId, this.props.foreignKeyId).then(res => {
                        this.setState({...res.data});
                    }),
                    productsClient().calculateStock(catalogId, this.props.foreignKeyId).then(res => {
                        this.setState({...res.data});
                    })
                ])
                .then(() => this.setState({loading: false, error: false}))
                .catch(() => this.setState({loading: false, error: true}));
        });
    };

    render() {
        const {webstoreProduct, attributes} = this.props;
        const {purchasable} = webstoreProduct;
        const priceHidden = _.get(webstoreProduct, 'price_hidden');
        const pickupOnly = _.get(webstoreProduct, 'pickup_in_store');

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <div
                        className={cs('row', 'my-md-3')}
                        id={'pss-pricing-availability'}
                    >
                        <div className={cs('col')}>
                            <NameValue name={'Part Number'} value={this.props.manufacturerPartNumber}/>

                            {this.state.loading && <Spinner/>}

                            {!this.state.loading && this.state.error &&
                            <React.Fragment>
                                <NameValue name={'Available'} value={'Currently Out Of Stock'}/>
                            </React.Fragment>
                            }

                            {!this.state.loading && !this.state.error &&
                            <CartConsumer>
                                {({currency, gatewayConfig}) => {
                                    let priceText = 'Call For Pricing';
                                    if (!gatewayConfig.disable_pricing) {
                                        if (!priceHidden) {
                                            priceText = this.state.salePrice > 0 ? currency(this.state.salePrice).format(true) : priceText;
                                        } else if (purchasable) {
                                            priceText = 'Add To Cart';
                                        }
                                    }

                                    return (
                                        <React.Fragment>
                                            {!gatewayConfig.disable_pricing &&
                                            <React.Fragment>
                                                {this.state.salePrice < this.state.msrp && <NameValue name={'Retail Price'} value={<del className={cs('text-muted')}>{currency(this.state.msrp).format(true)}</del>}/>}
                                            </React.Fragment>
                                            }

                                            <NameValue name={'Your Price'} value={priceText}/>


                                            {!gatewayConfig.disable_transactions &&
                                            <React.Fragment>
                                                <NameValue name={'Availability'} value={this.state.quantity > 0 ? 'Yes' : 'Currently Out Of Stock'}/>

                                                {!pickupOnly && this.state.quantity > 0 && <NameValue name={'Ships Within'} value={`${this.state.leadTime} Day(s)`}/>}

                                                {this.state.pickup && this.state.quantity > 0 &&
                                                <React.Fragment>
                                                    {this.state.isShelf && <NameValue name={'In-Store Pickup'} value={'Available Today!'}/>}

                                                    {!this.state.isShelf && <NameValue name={'In-Store Pickup'} value={`Available Within ${this.state.leadTime} Day(s)`}/>}
                                                </React.Fragment>
                                                }
                                            </React.Fragment>
                                            }

                                            {gatewayConfig.disable_transactions && <NameValue name={'Available'} value={'Call For Availability'}/>}
                                        </React.Fragment>
                                    )
                                }}
                            </CartConsumer>
                            }

                            {_.map(attributes, (v, k) => (
                                <NameValue key={k} name={v.name} value={v.value} />
                            ))}
                        </div>
                    </div>
                )}
            </ThemeContext.Consumer>
        );
    }
}