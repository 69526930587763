import baseClient from 'src/client.webstore/api/base';
import productsClient from 'src/client.webstore/api/product';
import fitmentClient from 'src/client.webstore/api/fitment';
import {cart} from 'src/client.webstore/utils/cart';
import {gateway} from 'src/client.webstore/utils/gateway';
import {createProduct} from 'src/client.webstore/utils/createProduct';
import {routeShowcaseSearch} from 'src/client.webstore/utils/showcaseRouter';

window.dominoPartsLookup = {
    addToCart: cart.addOem
};

export * from './WebstoreComponents';

/** @namespace utils **/
export const utils = {
    cart,
    gateway,
    createProduct,
    routeShowcaseSearch
};

export const api = {
    baseClient,
    productsClient,
    fitmentClient
};
