import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import qs from 'query-string';
import productsClient from 'src/client.webstore/api/product';
import FixedLabel from 'src/client.webstore/Views/Elements/FixedLabel';
import ProductCardRefactor from 'src/client.webstore/Views/Elements/ProductCardRefactor';
import baseClient from 'src/client.webstore/api/base';
import ProductFilterRefactor from './Elements/ProductFilterRefactor';
import CartActions from './Elements/CartActions';
import CartActionsRefactor from './Elements/CartActionsRefactor';
import PricingAvailabilityRefactor from './Elements/PricingAvailabilityRefactor';
import PricingAvailability from './Elements/PricingAvailability';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import Helmet from 'react-helmet';
import analytics from 'src/client.webstore/utils/analytics';
import {Link} from 'react-router-dom';
import handleViewport from 'react-in-viewport';
import Spinner from 'src/client.webstore/Views/Elements/Spinner';
import {ToggleClassFlagProvider} from 'src/toggles/ToggleClassFlagProvider';
import ProductSlider from 'src/client.webstore/Views/Products/Elements/ProductSlider';
import styled from 'styled-components';

const Analytics = analytics();

// Updates the bootstrap 15px (30px total) gutter gap between Related Product cards down to 10px (20px total)
const ProductCardListWrapper = styled.div`
    .pss-closer-columns.row,
    .pss-closer-columns[class^="_row"],
    .pss-closer-columns[class^=" _row"] {
      margin-right: -10px;
      margin-left: -10px;
    }
    
    .pss-closer-columns > [class^="col-"],
    .pss-closer-columns > [class^=" col-"],
    .pss-closer-columns > [class^="_col-"],
    .pss-closer-columns > [class^=" _col-"] {
      padding-right: 10px;
      padding-left: 10px;
    }
`;

class ProductRow extends React.Component {
    static propTypes = {
        product: PropTypes.object.isRequired,
        manufacturerPartNumber: PropTypes.string.isRequired,
        cs: PropTypes.func.isRequired,
        webstoreProduct: PropTypes.object.isRequired,
        productGroup: PropTypes.object.isRequired
    }

    state = {
        beenVisible: false,
        attributes: []
    }

    componentDidMount() {
        this.setState({attributes: this.getAttributes()});
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.inViewport && !this.state.beenVisible) {
            this.setState({beenVisible: true});
        }
    }

    componentWillUnmount() {
        // Stop state updates after the component is unmounted to avoid potential memory leaks from any pending async requests
        this.setState = () => null;
    }

    getAttributes() {
        // Ignore Activity attributes, there can be multiple and they aren't useful for product display
        const ignoreAttributes = [
            'Activity',
            'Year',
            'Make',
            'Model'
        ];

        return _.filter(_.get(this.props.product, 'Attribute', []) || [], v => {
            return _.indexOf(ignoreAttributes, v.name) < 0;
        });
    }

    render() {
        const {cs, webstoreProduct} = this.props;
        const catalogId = Number(_.get(webstoreProduct, 'catalog_id', 0));
        const {attributes} = this.state;

        return (
            <div className={cs('row', 'my-3', 'border', 'bg-white', 'p-3')}>
                {!this.state.beenVisible &&
                <div className={cs('col')}>
                    <Spinner/>
                </div>
                }

                {(this.props.inViewport || this.state.beenVisible) &&
                <React.Fragment>
                    <div className={cs('col-md-2', 'align-self-center', 'text-center')}>
                        <img
                            src={productsClient().imageUrl(catalogId, this.props.product.id, 120, 120)} alt={`${this.props.manufacturerPartNumber} Variation`}
                            className={cs('img-fluid')}
                        />
                    </div>
                    <div className={cs('col-md-5', 'align-self-center')}>
                        <PricingAvailability
                            webstoreProduct={this.props.webstoreProduct}
                            foreignKeyId={Number(this.props.product.id)}
                            manufacturerPartNumber={this.props.product.manufacturer_part_number}
                            attributes={attributes}
                        />
                    </div>
                    <div className={cs('col-md-5', 'align-self-center')}>
                        <CartActionsRefactor
                            filteredProducts={[this.props.product]}
                            foreignKeyId={Number(this.props.product.id)}
                            webstoreProduct={this.props.webstoreProduct}
                            productGroup={this.props.productGroup}
                        />
                    </div>
                </React.Fragment>
                }
            </div>
        )
    }
}

ProductRow = handleViewport(ProductRow);

export default class ViewProductRefactor extends React.Component {
    state = {
        gallery: [],
        filterValues: {},
        loading: true,
        related: [],
        years: [],
        makes: [],
        models: [],
        error: false
    };

    static defaultState = {
        gallery: [],
        filterValues: {},
        loading: true,
        related: [],
        years: [],
        makes: [],
        models: [],
        error: false
    };

    componentDidMount() {
        this.loadProduct()
            .then(() => this.setDefaultValues())
            .then(() => this.setState({loading: false}))
            .catch(err => this.setState({error: true, loading: false}));
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState(ViewProductRefactor.defaultState, this.loadProduct);
        }
    }

    componentWillUnmount() {
        // Stop state updates after the component is unmounted to avoid potential memory leaks from any pending async requests
        this.setState = () => null;
    }

    /**
     * @return {Promise<void>}
     */
    loadProduct = () => productsClient()
        .getProduct(this.props.match.params.id)
        .then(res => {
            this.setState({...res.data});
            return res;
        })
        .then(res => {
            // Set default values from query params
            let query = qs.parse(this.props.location.search);
            const q = _.get(query, 'q', '') || '';

            if (!_.isEmpty(query) && q !== '') {
                query = JSON.parse(q);
            }

            // Pre-select specific fitment and a color/size/option if only one was selected
            const colors = _.get(query, 'color', []) || [];
            const sizes = _.get(query, 'size', []) || [];
            const options = _.get(query, 'option', []) || [];

            this.setValues({
                Color: colors.length === 1 ? _.head(colors) : this.getValue('Color'),
                Size: sizes.length === 1 ? _.head(sizes) : this.getValue('Size'),
                Option: options.length === 1 ? _.head(options) : this.getValue('Option'),
                Year: _.get(query, 'year', '') || this.getValue('Year'),
                Make: _.get(query, 'make', '') || this.getValue('Make'),
                Model: _.get(query, 'model', '') || this.getValue('Model')
            });

            return res;
        })
        // Preload year/make/model fitment data so that we can preselect the first value from those options
        // This section must block with an 'await' so that we don't set fitment values before we have the options (which could cause a setState loop)
        .then(async res => {
            const webstoreProductId = res.data.WebstoreProduct.id;

            // Fetch years, then makes, then models, and preselect their values
            await productsClient()
                .getYears(webstoreProductId)
                .then(async years => {
                    // Use the year already provided in query arguments if available, otherwise default to the first value
                    const year = years.data.includes(this.getValue('Year'))
                        ? this.getValue('Year')
                        : (_.get(years, 'data[0]', '') || '');

                    if (year.length > 0) {
                        await productsClient()
                            .getMakes(webstoreProductId, year)
                            .then(async makes => {
                                // Use the make already provided in query arguments if available, otherwise default to the first value
                                const make = makes.data.includes(this.getValue('Make'))
                                    ? this.getValue('Make')
                                    : (_.get(makes, 'data[0]', '') || '');

                                if (make.length > 0) {
                                    await productsClient()
                                        .getModels(webstoreProductId, year, make)
                                        .then(models => {
                                            // Use the model already provided in query arguments if available, otherwise default to the first value
                                            const model = models.data.includes(this.getValue('Model'))
                                                ? this.getValue('Model')
                                                : (_.get(models, 'data[0]', '') || '');

                                            if (model.length > 0) {
                                                this.setState(
                                                    {years: years.data, makes: makes.data, models: models.data},
                                                    () => this.setValues({Year: year, Make: make, Model: model})
                                                );
                                            } else {
                                                this.setState(
                                                    {years: years.data, makes: makes.data, models: models.data},
                                                    () => this.setValues({Year: year, Make: make})
                                                );
                                            }
                                        });
                                } else {
                                    this.setState({years: years.data, makes: makes.data}, () => this.setValue('Year', year));
                                }
                            })
                    } else {
                        this.setState({years: years.data});
                    }
                })
                // .then(() => res)
            return res;
        })
        .then(res => {
            productsClient()
                .get('/categories', {
                    length: 12,
                    manufacturer: res.data.ProductGroup.ManufacturerGroup.name,
                    ...qs.parse(this.props.location.search)
                })
                .then(res => this.setState({related: res.data.products}));

            return res;
        })
        .then(this.fireAnalytics);

    /**
     * @return {void}
     */
    fireAnalytics = webstoreProduct => {
        try {
            let {ProductGroup, WebstoreProduct} = webstoreProduct.data;

            Analytics.view({
                ecomm_part_detail_id: `${WebstoreProduct.id}`,
                ecomm_part_detail_name: ProductGroup.name,
                // ecomm_part_detail_original_price: '19.99',
                // ecomm_part_detail_sale_price: '14.99',
                // ecomm_part_detail_currency_code: 'USD',
                ecomm_part_detail_breadcrumbs: this.state.WebstoreCategory.map(v => v.name),
                ecomm_part_detail_brand: ProductGroup.ManufacturerGroup.name,
                // ecomm_part_detail_quantity: '12',
                // ecomm_part_detail_variant: JSON.stringify(this.state.filterValues),
                ecomm_part_detail_category: _.get(this.state, `WebstoreCategory[${this.state.WebstoreCategory.length - 1}].name`, ''),
                // ecomm_part_detail_model: filteredProducts[0].manufacturer_part_number,
                ecomm_part_detail_group_id: ProductGroup.id,
                // ecomm_part_detail_image_count: galleryColors.length,
                ecomm_part_detail_dscrptn_char_count: ProductGroup.description ? ProductGroup.description.length : 0,
                site_section: 'Ecommerce Part Detail Page',
                tealium_event: 'ecommerce_part_detail_view'
            });
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Sets default values for select filters
     */
    setDefaultValues = () => {
        const availableFilters = this.getAvailableFilters();

        let filterValues = {...this.state.filterValues};
        let tiers = [];
        _.map(availableFilters, v => {
            // Keep track of the filter order to use as tiers
            tiers.push(v.name);

            // Determine what filter values are available at this tier
            let availableValues = _.filter(v.values, value => this.isTieredValueAvailable(v.name, value, tiers));

            // Default the selected value to the first option in the list if not already selected
            let value = filterValues[v.name] || this.getValue(v.name);
            if (value === '' || !availableValues.includes(value)) {
                value = _.head(availableValues) || '';
            }

            // Override the filter values
            if (value !== '') {
                filterValues[v.name] = value;
            } else {
                delete filterValues[v.name];
            }
        });

        // Save the filter values in bulk to avoid too many state updates
        this.setState({filterValues});
    };

    /**
     * Sorts option values
     *
     * @param {Array} values An array of Strings
     * @param {Boolean} ascending Whether to order ASC or DESC, default ASC
     * @returns The given values after sorting
     */
    sortValues = (values, ascending = true) => {
        // Determine whether all values are numeric or not so they can be sorted differently
        let numeric = true;
        _.forEach(values, v => {
            if (isNaN(+v)) {
                numeric = false;
                return false;
            }
        });

        // Numeric values to be sorted ASC while strings are alphabetical ASC
        let sortAsc = [];
        if (numeric) {
            sortAsc = values.sort((a, b) => a - b);
        } else {
            sortAsc = values.sort((a, b) => ('' + a).localeCompare('' + b));
        }

        return ascending ? sortAsc : sortAsc.reverse();
    }

    /**
     * @param {string} key
     * @return {string}
     */
    getValue = key => this.state.filterValues[key] || '';

    /**
     * @param {string} key
     * @param {string} value
     * @param {Array} clears
     * @param {Function} callback A callback function to call after the value state is updated
     */
    setValue = (key, value, clears = [], callback = () => {}) => {
        let state = {...this.state.filterValues};

        _.forEach(clears, v => {
            delete state[v];
        });

        if (value !== '') {
            state[key] = value;
        } else {
            delete state[key];
        }

        this.setState({filterValues: state}, () => {
            if (typeof callback === 'function') {
                callback();
            }
        });
    };

    /**
     * Set values in bulk so fewer calls to setState are made
     *
     * @param {Collection} pairs The key => value pairs to set for filterValues
     */
    setValues = (pairs) => {
        let state = {...this.state.filterValues};

        _.map(pairs, (v, k) => {
            if (v !== '') {
                state[k] = v;
            } else {
                delete state[k];
            }
        });

        this.setState({filterValues: state});
    }

    /**
     * Reduce `this.state.AttributeMap` to include only options that have > 1
     * values to show as filterable dropdown menus. Special exceptions can be made
     * here to hide certain attributes from being filtered.
     *
     * NOTE: _.chain() is not used here for readability.
     *
     * @return {Array<object>}
     */
    getAvailableFilters = () => {
        let filters = _.map(this.state.AttributeMap, (v, k) => ({name: k, values: this.sortValues(v)}));

        /**
         * Reduce the filters by eligibility
         */
        filters = _.filter(filters, attributeType => {
            /**
             * Filter exceptions go here:
             */
            let ignoredAttributes = [
                'Primary Color',
                'Distinct Name',
                'Market Color',
                'Product Type',
                'Item Name',
                'Activity'
            ];

            if (_.indexOf(ignoredAttributes, attributeType.name) >= 0) {
                return false;
            }

            /**
             * Filter whitelist go here:
             */
            let whitelist = [
                'Size',
                'Color',
                'Option'
            ];

            return attributeType.values.length > 1 || _.indexOf(whitelist, attributeType.name) >= 0;
        });

        // If any fitment exists for the product, it must be tiered first
        let fitment = [];
        if (this.state.years.length > 0) {
            fitment.push({name: 'Year', values: this.sortValues(this.state.years, false)});

            if (this.state.makes.length > 0) {
                fitment.push({name: 'Make', values: this.sortValues(this.state.makes)});

                if (this.state.models.length > 0) {
                    fitment.push({name: 'Model', values: this.sortValues(this.state.models)});
                }
            }
        }

        return _.concat(fitment, filters);
    }

    /**
     * Reduces the available products in `this.state.ProductGroup.Product` based on
     * `this.state.filterValues` with the hopes that we will reduce the selection down to 1
     * available variation given the attributes
     *
     * @param {Array} attributes
     * @returns {Array}
     */
    filterTieredProducts = (attributes) => {
        if (_.isEmpty(this.state.filterValues)) {
            return this.state.ProductGroup.Product;
        }

        const filterValues = _.map(this.state.filterValues, (value, name) => ({name, value}));
        const tieredAttributes = _.filter(attributes, (attribute) => _.find(filterValues, ['name', attribute]));
        const lastAttribute = _.last(tieredAttributes);
        const tieredFilterValues = _.filter(filterValues, (o) => _.includes(tieredAttributes, o.name));

        return _.filter(this.state.ProductGroup.Product, product => {
            // The product is valid if no attributes are provided (i.e. it must be the top-tier/root attribute, all values should be available
            if (_.isEmpty(tieredAttributes)) {
                return true;
            }

            // Look for the last filter attribute and include only products that have it
            let valid = false;
            _.forEach(tieredAttributes, attribute => {
                // All previous tiered attributes are valid except for the last one, which is the one we are looking to filter
                if (attribute === lastAttribute) {
                    _.forEach(tieredFilterValues, filterValue => {
                        if (_.findIndex(product.Attribute, filterValue) < 0) {
                            valid = false;
                            return false;
                        }

                        valid = true;
                    });

                    return false;
                }

                valid = true;
            });

            return valid;
        });
    }

    /**
     * Given a reduced set of `this.filterValues`, is the given `value` available for `name` for the tiered attribute set?
     *
     * @param {string} name
     * @param {string} value
     * @param {Array} attributes
     * @return {boolean}
     */
    isTieredValueAvailable = (name, value, attributes = []) => {
        let availableAttributes = [];
        _.forEach(this.filterTieredProducts(attributes), product => {
            _.forEach(product.Attribute, attribute => availableAttributes.push(attribute))
        });

        return _.findIndex(availableAttributes, {name, value}) >= 0;
    }

    isFullyFiltered = () => {
        let filtered = true;

        if (this.state.years.length > 0 && (this.getValue('Year') === '' || this.getValue('Make') === '' || this.getValue('Model') === '')) {
            return false;
        }

        _.forEach(this.getAvailableFilters(), v => {
            if (v.values.length > 1 && this.getValue(v.name) === '') {
                filtered = false;
            }
        });

        return filtered;
    }

    /**
     * When the fitment year is changed, this updates makes and models accordingly
     * @param year
     */
    updateFromYear = (year) => {
        // Clear the makes/models, they will be refetched
        this.setState({makes: [], models: []}, () => {
            productsClient()
                .getMakes(this.state.WebstoreProduct.id, year)
                .then(makes => {
                    this.setState({makes: makes.data});

                    return makes.data.includes(this.getValue('Make')) ? this.getValue('Make') : _.get(makes, 'data[0]', '');
                })
                .then(make => this.updateFromMake(year, make))
        });
    }

    /**
     * When the fitment make is changed, this updates models accordingly
     * @param year
     * @param make
     */
    updateFromMake = (year, make) => {
        // Clear the models too, they will be refetched
        this.setState({models: []}, () => {
            productsClient()
                .getModels(this.state.WebstoreProduct.id, year, make)
                .then(res => this.setState({models: res.data}))
                .then(this.setDefaultValues)
        });
    }

    render() {
        if (this.state.loading) {
            return null;
        }

        if (this.state.error) {
            return (
                <ThemeContext.Consumer>
                    {({cs}) => (
                        <React.Fragment>
                            <Helmet>
                                <title>Uh oh!</title>
                            </Helmet>
                            <div className={cs('row', 'my-5', 'justify-content-center')}>
                                <div className={cs('col-6')}>
                                    <div className={cs('row')}>
                                        <div className={cs('col-12', 'text-center')}>
                                            <h2>Not Found</h2>
                                        </div>
                                        <div className={cs('col-12', 'my-3')}>
                                            <p className={cs('lead')}>
                                                We couldn't find the product you were looking for. It may have been removed or is no longer available for sale.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </ThemeContext.Consumer>
            )
        }

        const {WebstoreProduct, ProductGroup} = this.state;
        const {Product: availableProducts} = ProductGroup;
        const availableFilters = this.getAvailableFilters();
        const tiers = _.map(availableFilters, v => v.name);

        const galleryColors = _.chain(availableProducts)
            .filter(({color}) => _.includes(_.get(_.head(_.filter(availableFilters, v => v.name === 'Color')) || {values: []}, 'values'), color))
            .map(({id, color}) => ({id: id, color: color}))
            .uniqBy(p => p.color)
            .sortBy('color')
            .value();

        const filteredProducts = this.filterTieredProducts(tiers);

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <React.Fragment>
                        <Helmet>
                            <title>{ProductGroup.ManufacturerGroup.name} {ProductGroup.name}</title>
                        </Helmet>

                        <ToggleClassFlagProvider
                            flagName={'test'}
                            render={({isEnabled, getClient}) => {
                                // Render nothing, this is just a test to console that the toggle works
                                // Note that this does not play well with component children that use react-helmet
                                console.log('Test toggle ' + (isEnabled() ? 'enabled' : 'disabled'));
                                return <></>
                            }}
                        />

                        <div className={cs('row')}>
                            <div className={cs('col-12')}>
                                <nav aria-label={'breadcrumb'}>
                                    <ol className={cs('breadcrumb')}>
                                        <li className={cs('breadcrumb-item')}>
                                            <a href='/'>Home</a>
                                        </li>
                                        <li className={cs('breadcrumb-item')}>
                                            <Link to='/categories'>All Parts & Accessories</Link>
                                        </li>
                                        {this.state.WebstoreCategory && this.state.WebstoreCategory.map((v, k) => {
                                            if (!v.webstore_url) {
                                                return (
                                                    <li key={k} className={cs('breadcrumb-item', 'active')}>
                                                        {v.name}
                                                    </li>
                                                )
                                            }

                                            return (
                                                <li key={k} className={cs('breadcrumb-item')}>
                                                    {v.webstore_url &&
                                                    <Link to={`/categories${v.webstore_url}`}>
                                                        {v.name}
                                                    </Link>
                                                    }
                                                </li>
                                            )
                                        })}
                                    </ol>
                                </nav>
                            </div>
                        </div>

                        <div className={cs('row', 'mb-3')}>
                            <div className={cs('col-md-7', 'col-lg-7', 'mb-3')}>
                                {WebstoreProduct.on_sale &&
                                <FixedLabel label={'ON SALE'} side={'left'} className={cs('bg-danger', 'text-white', 'p-2', 'shadow')} />
                                }

                                <div className={cs('rounded', 'border', 'shadow-sm', 'bg-white')}>
                                    {(filteredProducts.length > 0 || galleryColors.length > 1) &&
                                        <ProductSlider
                                            catalogId={Number(WebstoreProduct.catalog_id)}
                                            foreignKeyId={filteredProducts.length > 0 ? Number(filteredProducts[0].id) : 0}
                                            selectedColor={this.getValue('Color')}
                                            colors={galleryColors.length > 1 ? galleryColors : [{
                                                id: Number(filteredProducts[0].id),
                                                color: ''
                                            }]}
                                            updateColor={(color) => color.length ? this.setValue('Color', color, [], this.setDefaultValues) : null}
                                        />
                                    }
                                </div>
                            </div>

                            <div className={cs('col-md-5', 'col-lg-5')}>
                                <div className={cs('row')}>
                                    <div className={cs('col', 'order-0', 'order-lg-0')}>
                                        <h5 className={cs('text-muted')}><u>{ProductGroup.ManufacturerGroup.name}</u></h5>
                                        <h4 className={cs('my-0')}>{ProductGroup.name}</h4>

                                        {filteredProducts.length === 1 &&
                                            <PricingAvailabilityRefactor
                                                webstoreProduct={WebstoreProduct}
                                                foreignKeyId={Number(filteredProducts[0].id)}
                                                manufacturerPartNumber={filteredProducts[0].manufacturer_part_number}
                                            />
                                        }

                                        {_.map(availableFilters, v => {
                                            // Get the available values based on the previous tiered filters
                                            const availableValues = _.filter(v.values, value => this.isTieredValueAvailable(v.name, value, _.slice(tiers, 0, _.indexOf(tiers, v.name))));
                                            const isFitment = ['Year', 'Make', 'Model'].includes(v.name);

                                            return (
                                                <ProductFilterRefactor
                                                    key={v.name}
                                                    name={v.name}
                                                    label={v.name}
                                                    options={availableValues}
                                                    setFieldValue={(k, v) => {
                                                        // All value changes should be set and then preselect the next tiers with default values
                                                        // However, fitment changes must refresh make and model data too
                                                        switch (k) {
                                                            case 'Year':
                                                                this.setValue('Year', v, [], () => this.updateFromYear(v));
                                                                break;
                                                            case 'Make':
                                                                this.setValue('Make', v, [], () => this.updateFromMake(this.getValue('Year'), v));
                                                                break;
                                                            default:
                                                                this.setValue(k, v, [], this.setDefaultValues);
                                                                break;
                                                        }
                                                    }}
                                                    value={this.getValue(v.name)}
                                                    disabled={!isFitment && this.state.years.length > 0 && availableValues.length > 0 && (this.getValue('Year') === '' || this.getValue('Make') === '' || this.getValue('Model') === '')}
                                                />
                                            )
                                        })}

                                        {(!this.isFullyFiltered() || (this.isFullyFiltered() && filteredProducts.length === 1)) &&
                                        <CartActionsRefactor
                                            foreignKeyId={filteredProducts.length > 0 ? Number(filteredProducts[0].id) : null}
                                            filteredProducts={filteredProducts}
                                            webstoreProduct={WebstoreProduct}
                                            productGroup={ProductGroup}
                                        />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.isFullyFiltered() && filteredProducts.length > 1 &&
                        <div className={cs('row', 'mb-3')}>
                            <div className={cs('col-12', 'mb-2')}>
                                <h2>Part Numbers</h2>
                            </div>
                            <div className={cs('col-12', 'mb-2')}>
                                {filteredProducts.map((v, k) => (
                                    <ProductRow
                                        key={k}
                                        product={v}
                                        manufacturerPartNumber={v.manufacturer_part_number}
                                        cs={cs}
                                        webstoreProduct={WebstoreProduct}
                                        productGroup={ProductGroup}
                                    />
                                ))}
                            </div>
                        </div>
                        }

                        {ProductGroup.description &&
                        <div className={cs('row', 'mb-3')}>
                            <div className={cs('col-12', 'mb-2')}>
                                <h2>Product Information</h2>
                            </div>
                            <div className={cs('col-12', 'px-4')} style={{fontSize: '16px'}}>
                                <div className={cs('bg-white')}>
                                    <div className={cs('row')}>
                                        <div className={cs('col')}>
                                            {ProductGroup.description && <div dangerouslySetInnerHTML={{__html: ProductGroup.description}}/>}
                                        </div>
                                    </div>

                                    {typeof this.state.AttributeMap === 'object' &&
                                    <div className={cs('row', 'my-4')}>
                                        <div className={cs('col')}>
                                            {_.map(this.state.AttributeMap, (v, k) => {
                                                if (v.length === 1) {
                                                    return (
                                                        <div className={cs('row')} key={k}>
                                                            <div className={cs('col')}>
                                                                <span className={cs('font-weight-bolder')}>{k}:</span>
                                                                {' '}
                                                                {v[0]}
                                                            </div>
                                                        </div>
                                                    );
                                                }

                                                return null;
                                            })}
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        }

                        {this.state.related.length > 0 &&
                        <ProductCardListWrapper>
                            <div className={cs('row', 'mb-3', 'pss-closer-columns')}>
                                <div className={cs('col-12', 'mb-3')}>
                                    <h2>Related Products</h2>
                                </div>
                                {this.state.related.map((v, k) => (
                                    <ProductCardRefactor
                                        key={v.WebstoreProduct.id}
                                        webstoreProductId={v.WebstoreProduct.id}
                                        manufacturerGroupName={v.ProductGroup.ManufacturerGroup.name}
                                        productGroupName={v.ProductGroup.name}
                                        imageUrl={() => {
                                            if (v.WebstoreProduct.image_url) {
                                                return v.WebstoreProduct.image_url;
                                            }

                                            return baseClient().resolveUrl(`/webstore/webstore_images/resize_hero/${v.WebstoreProduct.id}/500/500`);
                                        }}
                                        minPrice={v.WebstoreProduct.min_price}
                                        products={v.ProductGroup.Product}
                                        onSale={v.WebstoreProduct.on_sale}
                                    />
                                ))}
                            </div>
                        </ProductCardListWrapper>
                        }
                    </React.Fragment>
                )}
            </ThemeContext.Consumer>
        );
    }
}
