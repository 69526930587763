import React from 'react';
import PropTypes from 'prop-types';
import productsClient from 'src/client.webstore/api/product';
import Spinner from 'src/client.webstore/Views/Elements/Spinner';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import {CartConsumer} from 'src/client.webstore/Views/Cart/CartContext';
import ShippingAvailability from 'src/client.webstore/Views/Products/Elements/ShippingAvailability';
import _ from 'lodash';
import styled from 'styled-components';

// Custom CSS that renders the cart actions
const PricingAvailabilityWrapper = styled.div`
  // Updates the bootstrap 15px (30px total) gutter gap between in-store pickup/ship actions down to 10px (20px total)
  .pss-closer-columns.row,
  .pss-closer-columns[class^="_row"],
  .pss-closer-columns[class^=" _row"] {
    margin-right: -10px;
    margin-left: -10px;
  }

  .pss-closer-columns > [class^="col-"],
  .pss-closer-columns > [class^=" col-"],
  .pss-closer-columns > [class^="_col-"],
  .pss-closer-columns > [class^=" _col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
`;

/**
 * Renders Pricing and Shipping options for a particular product
 */
export default class PricingAvailabilityRefactor extends React.Component {
    static propTypes = {
        foreignKeyId: PropTypes.number.isRequired,
        manufacturerPartNumber: PropTypes.string.isRequired,
        webstoreProduct: PropTypes.object.isRequired
    };

    state = {
        loading: true,
        error: false,
        salePrice: null,
        msrp: null,
        isShelf: false,
        leadTime: 5,
        quantity: 0,
        pickup: false
    };

    componentDidMount() {
        this.calculate();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.foreignKeyId !== this.props.foreignKeyId) {
            this.calculate();
        }
    }

    componentWillUnmount() {
        // Stop state updates after the component is unmounted to avoid potential memory leaks from any pending async requests
        this.setState = () => null;
    }

    calculate = () => {
        const catalogId = Number(_.get(this.props, 'webstoreProduct.catalog_id', 0));

        this.setState({loading: true}, () => {
            Promise
                .all([
                    productsClient().calculatePrice(catalogId, this.props.foreignKeyId).then(res => {
                        this.setState({...res.data});
                    }),
                    productsClient().calculateStock(catalogId, this.props.foreignKeyId).then(res => {
                        this.setState({...res.data});
                    })
                ])
                .then(() => this.setState({loading: false, error: false}))
                .catch(() => this.setState({loading: false, error: true}));
        });
    };

    render() {
        const {webstoreProduct} = this.props;
        const {purchasable} = webstoreProduct;
        const priceHidden = _.get(webstoreProduct, 'price_hidden');
        let pickupOnly = _.get(webstoreProduct, 'pickup_in_store');

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <PricingAvailabilityWrapper>
                        <div className={cs('row', 'mt-2', 'mb-3')} id={'pss-product-pricing'}>
                            <div className={cs('col')}>
                                <p><span>Part Number: {this.props.manufacturerPartNumber}</span></p>

                                {this.state.loading && <Spinner/>}

                                {!this.state.loading && this.state.error &&
                                    <div className={cs('text-danger')}>
                                        Currently Out Of Stock
                                    </div>
                                }

                                {!this.state.loading && !this.state.error &&
                                    <CartConsumer>
                                        {({currency, gatewayConfig}) => {
                                            // if In-Store Pickup Only checked at webstore level then override and don't show shipping option
                                            pickupOnly = gatewayConfig.pickup.inStorePickupOnly ? gatewayConfig.pickup.inStorePickupOnly : pickupOnly;
                                            // if In-Store Pickup is disabled at webstore level then oveeride and always shows shipping option
                                            pickupOnly = gatewayConfig.pickup.inStorePickup === 0 ? false : pickupOnly;

                                            let priceText = 'Call For Pricing';
                                            if (!gatewayConfig.disable_pricing) {
                                                if (!priceHidden) {
                                                    priceText = this.state.salePrice > 0 ? currency(this.state.salePrice).format(true) : priceText;
                                                } else if (purchasable) {
                                                    priceText = 'Add To Cart';
                                                }
                                            }

                                            return (
                                                <>
                                                    {!gatewayConfig.disable_pricing &&
                                                        <>
                                                            {this.state.salePrice < this.state.msrp &&
                                                            <h5>
                                                                Retail Price: <del className={cs('text-muted')}>{currency(this.state.msrp).format(true)}</del>
                                                            </h5>
                                                            }
                                                        </>
                                                    }

                                                    <h4>Your Price: {priceText}</h4>

                                                    {gatewayConfig.disable_transactions &&
                                                        <div className={cs('text-danger')}>
                                                            Call For Availability
                                                        </div>
                                                    }

                                                    {!gatewayConfig.disable_transactions &&
                                                        <>
                                                            {this.state.quantity > 0 && this.state.quantity <= 3 &&
                                                                <div className={cs('text-danger')}>
                                                                    Only {this.state.quantity} in stock
                                                                </div>
                                                            }

                                                            {this.state.quantity > 0 &&
                                                                <div className={cs('row', 'pt-3', 'pss-closer-columns')} style={{opacity: 0.65}}>
                                                                    {this.state.pickup &&
                                                                        <div className={cs('col-6')}>
                                                                            <ShippingAvailability
                                                                                cs={cs}
                                                                                type={'pickup'}
                                                                                leadTime={Number(this.state.leadTime)}
                                                                                isShelf={this.state.isShelf}
                                                                            />
                                                                        </div>
                                                                    }

                                                                    {!pickupOnly &&
                                                                        <div className={cs('col-6')}>
                                                                            <ShippingAvailability
                                                                                cs={cs}
                                                                                type={'ship'}
                                                                                leadTime={Number(this.state.leadTime)}
                                                                                isShelf={this.state.isShelf}
                                                                            />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            );
                                        }}
                                    </CartConsumer>
                                }
                            </div>
                        </div>
                    </PricingAvailabilityWrapper>
                )}
            </ThemeContext.Consumer>
        );
    }
}
