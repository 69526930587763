import React from 'react';
import PropTypes from 'prop-types';
import {Badge, Pane, Position, Tooltip} from 'evergreen-ui';
import productsClient from 'src/client.webstore/api/product';
import {product, product as cartProduct} from 'src/client.webstore/utils/cart';
import {ThemeContext} from 'src/client.webstore/Context/StyleProvider';
import CartContext from 'src/client.webstore/Views/Cart/CartContext';
import _ from 'lodash';

export default class CartItem extends React.Component {
    static propTypes = {
        catalogId: PropTypes.number.isRequired,
        foreignKeyId: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        allowEdit: PropTypes.bool,
        settings: PropTypes.object
    };

    static defaultProps = {
        allowEdit: true
    }

    state = {
        loading: true
    };

    componentDidMount() {
        Promise
            .all([
                productsClient()
                    .getVariation(this.props.catalogId, this.props.foreignKeyId)
                    .then(res => this.setState({...res.data})),
                productsClient()
                    .calculatePrice(this.props.catalogId, this.props.foreignKeyId)
                    .then(res => this.setState({...res.data})),
                productsClient()
                    .calculateStock(this.props.catalogId, this.props.foreignKeyId)
                    .then(res => this.setState({...res.data}))
            ])
            .then(() => this.setState({loading: false}))
            .catch(e => {
                product(this.props.catalogId, this.props.foreignKeyId).remove().then(() => {
                    window.location.reload();
                });
            });
    }

    render() {
        if (this.state.loading) {
            return null;
        }

        let product = this.state;
        let {ProductGroup: productGroup} = product;

        return (
            <ThemeContext.Consumer>
                {({cs}) => (
                    <CartContext.Consumer>
                        {({currency, calculate, gatewayConfig}) => (
                            <div className={cs('row', 'mb-5')}>
                                <div className={cs('col')}>
                                    <div className={cs('bg-white', 'border', 'p-2', 'shadow-sm')}>
                                        <div className={cs('row', 'p-1')}>
                                            <div className={cs('col-3', 'align-self-center')}>
                                                <img
                                                    src={productsClient().imageUrl(this.props.catalogId, this.props.foreignKeyId, 250, 250)}
                                                    alt={productGroup.name}
                                                    className={cs('img-fluid')}
                                                />
                                            </div>
                                            <div className={cs('col-9', 'col-md')}>
                                                <h5 className={cs('mb-3')}>{productGroup.name}</h5>

                                                <p className={cs('lead')}>Part #: {product.manufacturer_part_number}</p>

                                                {(_.get(gatewayConfig, 'pickup.inStorePickupOnly', false)
                                                    || (_.get(this.props.settings, 'pickup_in_store')
                                                            // OEM products allowed for shipment are not pickup-in-store regardless of the product.pickup_in_store setting
                                                            && !(_.get(gatewayConfig, 'pickup.allowOemShipments') && this.props.catalogId === 2)
                                                        )
                                                    ) &&
                                                <Tooltip content={'This product is only available for In-Store Pickup.'} position={Position.BOTTOM}>
                                                    <Pane display={'flex'} alignItems={'center'} marginBottom={16}>
                                                        <Badge color={'yellow'} display={'inline-table'}>In-Store Pickup Only</Badge>
                                                    </Pane>
                                                </Tooltip>
                                                }

                                                {product.size && <p className={cs('lead')}>Size: {product.size}</p>}
                                                {product.color && <p className={cs('lead')}>Color: {product.color}</p>}
                                                {product.option && <p className={cs('lead')}>Option: {product.option}</p>}
                                            </div>
                                            <div className={cs('col', 'align-self-center', 'text-center', 'my-3', 'my-md-0')}>
                                                {this.props.allowEdit &&
                                                <div className={cs('row')}>
                                                    <div className={cs('col-12')}>
                                                        <div className={cs('row', 'no-gutters')}>
                                                            <div className={cs('col-3')}>
                                                                <button
                                                                    type={'button'}
                                                                    title={'Remove 1 Quantity'}
                                                                    className={cs('btn', 'btn-lg', 'btn-secondary', 'w-100', 'text-center')}
                                                                    disabled={this.props.quantity === 1}
                                                                    onClick={() => {
                                                                        cartProduct(this.props.catalogId, this.props.foreignKeyId)
                                                                            .add(this.props.quantity - 1)
                                                                            .then(calculate)
                                                                    }}
                                                                >
                                                                    -
                                                                </button>
                                                            </div>
                                                            <div className={cs('col', 'text-center', 'align-self-center')}>
                                                                <p className={cs('lead')}>{this.props.quantity}</p>
                                                            </div>
                                                            <div className={cs('col-3')}>
                                                                <button
                                                                    type={'button'}
                                                                    title={'Add 1 Quantity'}
                                                                    className={cs('btn', 'btn-lg', 'btn-secondary', 'w-100', 'text-center')}
                                                                    disabled={this.props.quantity === this.state.quantity}
                                                                    onClick={() => {
                                                                        cartProduct(this.props.catalogId, this.props.foreignKeyId)
                                                                            .add(this.props.quantity + 1)
                                                                            .then(calculate)
                                                                    }}
                                                                >
                                                                    +
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={cs('col-12')}>
                                                        {this.props.allowEdit &&
                                                        <button
                                                            type={'button'}
                                                            className={cs('btn', 'btn-link')}
                                                            onClick={() => cartProduct(this.props.catalogId, this.props.foreignKeyId).remove().then(calculate)}
                                                        >
                                                            Remove
                                                        </button>
                                                        }
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                            <div className={cs('col', 'align-self-center', 'text-center', 'my-3', 'my-md-0')}>
                                                <p className={cs('lead')}>
                                                    {currency(this.state.salePrice).format(true)}{' '}
                                                    <small className={cs('text-muted')}>ea.</small>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </CartContext.Consumer>
                )}
            </ThemeContext.Consumer>
        );
    }
}
